import React from 'react'
import Link from 'gatsby-link'
import Header from "../components/header.js"
import Footer from "../components/footer.js"
import panel from '../components/panel.module.css'
import styles from './content.module.css'
import {Helmet} from 'react-helmet'

const ContentPage = () => (
  <div>  
    <Header />
    <Helmet>
        <title>Content</title>
    </Helmet>
    <div className={panel.panel}>
      <div className={panel.centeredText}>

        <h1 className={panel.h}>Content</h1> 
        <div>(Please enjoy!)</div> 

        <div className={styles.borderLine}></div> 

            <h2 className={panel.h}>
                <Link to='/content/aa'>Athanasia August</Link>
            </h2> 
            <div className={styles.descwrapper}>
                <div className={styles.description}>
                    Short stories following Athanasia August, the legendary Hero of a Million Prophecies. <br />More on the first and third Monday of each month.
                </div> 
            </div>
            <div className={styles.readMenu}>
                <Link to='/content/aa/meteors-and-vacuums-1'>&lt;&lt;&lt; Read First </Link> 
                |
                <Link to='/content/aa'> Story Home </Link> 
                | 
                <Link to='/content/aa/meteors-and-vacuums-5'> Read Latest &gt;&gt;&gt;</Link>
            </div> 

        <div className={styles.borderLine}></div>

            <h2 className={panel.h}>
                <a href='/content/varenlay'>Varen Lay's Guaranteed-Delivery Transport Service</a>
            </h2> 
            <div className={styles.descwrapper}>
                <div className={styles.description}>
                    A serial following the disparate journeys of Tibuci, Tyrant Queen of Amn, and Eight, former Child of the State, as they traverse the remains of the multiverse after the end of time and space. <br />More on the second and fourth Monday of each month.
                </div> 
            </div>
            <div className={styles.readMenu}>
                <Link to='/content/varenlay/step0'>&lt;&lt;&lt; Read First </Link> 
                |
                <Link to='/content/varenlay'> Story Home </Link> 
                | 
                <Link to='/content/varenlay/step4'> Read Latest &gt;&gt;&gt;</Link>
            </div> 

        <div className={styles.borderLine}></div>

            <h2 className={panel.h}>
                <a href='/content/permutations'>Permutations</a>
            </h2> 
            <div className={styles.descwrapper}>
                <div className={styles.description}>
                    A short story comprised of seven scenes presented in a random order. The cast of characters and flow of story should feel variable depending on the order in which the scenes are presented.
                </div> 
            </div>
            <div className={styles.readMenu}>
                <Link to='/content/permutations'>&lt;&lt;&lt; Read &gt;&gt;&gt;</Link> 
            </div> 

        <div className={styles.borderLine}></div> 

      </div>
    </div>
    <Footer />
  </div>
)

export default ContentPage
